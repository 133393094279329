import { t } from "i18next";
import update from "immutability-helper";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import ContainerSpeakerModal from "@application/Containers/ContainerSpeakerModal";
import PresentationController from "@application/Controllers/PresentationController";
import SessionController from "@application/Controllers/SessionController";
import {
	AppBox,
	ConfirmationModal,
	type DtoFaculty,
	type DtoPresentation,
	EnumContextualMenu,
	EnumIcon,
	NoData,
	QUERY_KEYS_PROGRAMME,
	Services,
	type TypeContextualMenu,
	queryStringSorts,
	useContextModule,
	useSnackBarHook,
	useToggle,
} from "@key4-front-library/core";
import { IconOld } from "@key4-front-library/core/Bo/Components";
import AccordionGroup from "@key4-front-library/core/Bo/Components/AccordionGroup";
import { ButtonTitle } from "@key4-front-library/core/Bo/Components/Button/ButtonTitle";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import { Box, Divider, Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { PresentationModal } from "@components";
import PresentationItemDetails from "./PresentationItemDetails";
import PresentationItemSummary from "./PresentationItemSummary";

export const Presentations = () => {
	const { sessionId = "" } = useParams();

	const theme = useTheme();
	const { sendSuccess, sendError } = useSnackBarHook();
	const { client, event } = useContextModule();
	const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
	const queryClient = useQueryClient();

	const [presentations, setPresentations] = useState<Array<DtoPresentation>>([]);
	const [presentationId, setPresentationId] = useState<string | undefined>();

	const [editSpeakerModalIsOpen, setEditSpeakerModalIsOpen] = useState(false);
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
	const { isToggle: isOpenPresentationModal, toggle: toggleOpenPresentationModal } = useToggle();

	const [presentationsSpeakers, setPresentationsSpeakers] = useState<Array<{ presentationId: string; speakers: Array<DtoFaculty> }>>([]);

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	const {
		isLoading,
		isError,
		isFetching,
		refetch: handleAllPresentationsRefetch,
	} = useQuery({
		queryKey: ["presentations", sessionId],
		queryFn: async () =>
			await Services.Events.Programme.SessionsService.getListPresentations(client.id, event.id, sessionId, [...queryStringSorts(["order"])]).then(
				(response) => {
					setPresentations(response);
					return response;
				},
			),
		enabled: !!sessionId,
	});

	const { refetch: handleSpeakersUpdate } = useQuery({
		queryKey: ["speakers", presentationId],
		queryFn: async () =>
			await Services.Events.Programme.PresentationsService.getSpeakersList(client.id, event.id, sessionId, presentationId!, [
				...queryStringSorts(["order"]),
			]).then((response) => {
				const updatedPresentations = presentationsSpeakers;
				updatedPresentations.forEach(
					(presentation: {
						presentationId: string;
						speakers: Array<DtoFaculty>;
					}) => {
						if (presentation.presentationId === presentationId) {
							presentation.speakers = response;
						}
					},
				);

				setPresentationsSpeakers(updatedPresentations);
				if (!editSpeakerModalIsOpen && !isOpenPresentationModal) {
					setPresentationId(undefined);
				}
				return response;
			}),

		enabled: !!presentationId,
	});

	const getAllSpeakers = async (presentations: Array<DtoPresentation>) => {
		const speakersList = [];
		try {
			for (const presentation of presentations) {
				const speakers = await Services.Events.Programme.PresentationsService.getSpeakersList(client.id, event.id, sessionId, presentation.id, [
					...queryStringSorts(["order"]),
				]);
				speakersList.push({ presentationId: presentation.id, speakers });
			}
			setPresentationsSpeakers(speakersList);
		} catch {
			sendError(t("old.errors.generic"));
		}
	};

	const handleConfirmationModalOpen = () => {
		setConfirmationModalIsOpen(true);
	};

	const handleConfirmationModalClose = () => {
		setConfirmationModalIsOpen(false);
	};

	const handlePresentationClone = async (id: string) => {
		try {
			await PresentationController.clone(client.id, event.id, sessionId, id).then(() => {
				handleAllPresentationsRefetch();
				sendSuccess(t("old.programme.presentations.create_edit_modale.snackbar.cloned"));
			});
		} catch {
			sendError(t("old.errors.generic"));
		}
	};

	const handlePresentationDelete = async (id: string) => {
		try {
			await PresentationController.deleteEntity(client.id, event.id, sessionId, id).then(() => {
				handleConfirmationModalClose();
				handleAllPresentationsRefetch();
				sendSuccess(t("old.programme.presentations.create_edit_modale.snackbar.deleted"));
			});
		} catch {
			sendError(t("old.errors.generic"));
		}
	};

	const handlePresentationMoveUp = (index: number) => handlePresentationMove(index, index - 1);

	const handlePresentationMoveDown = (index: number) => handlePresentationMove(index, index + 1);

	const handlePresentationMove = async (dragIndex: number, hoverIndex: number) => {
		const list = update(presentations, {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, presentations[dragIndex]],
			],
		});

		await SessionController.updateSessionPresentationsOrder(
			client.id,
			event.id,
			sessionId,
			list.map((presentation) => presentation.id),
		).then(() => {
			handleAllPresentationsRefetch();
		});
	};

	const menu: TypeContextualMenu = {
		items: [
			{
				label: t("old.programme.presentations.buttons.addSpeaker"),
				reference: EnumContextualMenu.ADD,
				icon: EnumIcon.PLUS,
				onClick: (value?: string) => {
					setPresentationId(value);
					setEditSpeakerModalIsOpen(true);
				},
			},
			"divider",
			{
				label: t("old.form.buttons.edit"),
				reference: EnumContextualMenu.EDIT,
				icon: EnumIcon.PEN_TO_SQUARE,
				onClick: (id?: string) => {
					setPresentationId(id);
					toggleOpenPresentationModal();
				},
			},
			{
				label: t("old.form.buttons.clone"),
				reference: EnumContextualMenu.CLONE,
				icon: EnumIcon.CLONE,
				onClick: (id?: string) => {
					handlePresentationClone(id ?? "");
				},
			},
			"divider",
			{
				label: t("old.programme.presentations.buttons.moveUp"),
				reference: EnumContextualMenu.UP,
				icon: EnumIcon.UP_LONG,
				onClick: (_?: string, index?: number) => {
					handlePresentationMoveUp(index ?? 0);
				},
			},
			{
				label: t("old.programme.presentations.buttons.moveDown"),
				reference: EnumContextualMenu.DOWN,
				icon: EnumIcon.DOWN_LONG,
				onClick: (_?: string, index?: number) => {
					handlePresentationMoveDown(index ?? 0);
				},
			},
			"divider",
			{
				label: t("old.form.buttons.delete"),
				reference: EnumContextualMenu.DELETE,
				icon: EnumIcon.TRASH_CAN,
				onClick: (id?: string) => {
					confirmationModalProps.current = {
						action: EConfirmationModalAction.DELETE,
						handleAction: () => {
							handlePresentationDelete(id ?? "");
						},
					};
					handleConfirmationModalOpen();
				},
			},
		],
	};

	const renderSummary = (presentation: DtoPresentation, isExpanded: boolean) => {
		return (
			<PresentationItemSummary
				isExpanded={isExpanded}
				presentation={presentation}
				speakers={
					presentationsSpeakers.find((item: { presentationId: string; speakers: Array<DtoFaculty> }) => item.presentationId === presentation.id)?.speakers ?? []
				}
			/>
		);
	};

	const renderDetails = (presentation: DtoPresentation) => {
		return (
			<PresentationItemDetails
				presentation={presentation}
				sessionId={sessionId}
				speakers={
					presentationsSpeakers.find((item: { presentationId: string; speakers: Array<DtoFaculty> }) => item.presentationId === presentation.id)?.speakers ?? []
				}
				updateSpeakers={() => {
					setPresentationId(presentation.id);
				}}
			/>
		);
	};

	const handleCallbackSubmitPresentation = () => {
		void handleAllPresentationsRefetch();
		void queryClient.invalidateQueries({
			queryKey: [...QUERY_KEYS_PROGRAMME.presentations, "Get", client.id, event.id, sessionId],
		});
	};

	useEffect(() => {
		handleAllPresentationsRefetch();
	}, []);

	useEffect(() => {
		if (presentations) {
			getAllSpeakers(presentations);
		}
	}, [presentations]);

	useEffect(() => {
		if (presentationId) {
			handleSpeakersUpdate();
		}
	}, [presentationId, editSpeakerModalIsOpen]);

	if (isError) {
		return <>{t("old.errors.generic")}</>;
	}

	if (!presentations) {
		return <NoData />;
	}

	return (
		<>
			{isFetching ? (
				<AppBox>
					<Skeleton height={"20vh"} />
				</AppBox>
			) : (
				<AppBox>
					<Stack direction={"column"} spacing={1}>
						<Stack direction={isMobileSize ? "column" : "row"} justifyContent={"space-between"} alignItems={"baseline"} spacing={1} py={1}>
							<Stack direction={"row"} alignItems={"baseline"} spacing={1}>
								<IconOld iconName={"podium"} prefix={"fal"} size={"3x"} color={theme.palette.primary.light} />
								<Typography variant={"h4"} fontWeight={"300"}>
									{t("old.programme.presentations.title")}
								</Typography>
							</Stack>

							<Box mb={2} alignSelf={isMobileSize ? "end" : "auto"}>
								<ButtonTitle
									icon={{ prefix: "fal", iconName: "plus" }}
									color="primary"
									label={t("old.programme.sessionDetails.presentations.titleButton")}
									handleClick={() => {
										setPresentationId(undefined);
										toggleOpenPresentationModal();
									}}
								/>
							</Box>
						</Stack>
						{presentations.length ? (
							<>
								<Divider color={theme.palette.grey[100]} />
								<Stack py={1}>
									<AccordionGroup
										isLoading={isLoading || isFetching}
										isDraggable={false}
										items={presentations}
										menu={menu}
										renderSummary={renderSummary}
										renderDetails={renderDetails}
										onChangeOrder={() => {
											console.error("AccordionGroup onChangeOrder is not implemented");
										}}
									/>
								</Stack>
							</>
						) : (
							<Stack pt={4}>
								<Stack
									py={5}
									alignItems={"center"}
									sx={{
										backgroundColor: theme.palette.background.tone,
									}}
								>
									<Typography variant="body1">{t("old.common.no-data")}</Typography>
								</Stack>
							</Stack>
						)}
					</Stack>
				</AppBox>
			)}

			{editSpeakerModalIsOpen && presentationId && (
				<ContainerSpeakerModal
					isOpen={editSpeakerModalIsOpen}
					changeIsOpen={setEditSpeakerModalIsOpen}
					speakerId={undefined}
					presentationId={presentationId}
					sessionId={sessionId}
				/>
			)}

			<ConfirmationModal
				open={confirmationModalIsOpen}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleConfirmationModalClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
			{isOpenPresentationModal && (
				<PresentationModal
					isOpened={isOpenPresentationModal}
					onClose={toggleOpenPresentationModal}
					sessionId={sessionId}
					id={presentationId}
					onCallbackSubmit={handleCallbackSubmitPresentation}
				/>
			)}
		</>
	);
};
