import type { InvitationStatusSpeaker, SessionStatusType } from "@api";
import { BulletText, type TagProps } from "@key4-front-library/core";
import type { Theme } from "@mui/material";
import { t } from "i18next";

export const getSessionStatus = (theme: Theme): Record<SessionStatusType, { name: string; color: string }> => ({
	draft: {
		color: theme.palette.grey[700],
		name: t("sessionStatus.draft"),
	},
	validated: {
		color: theme.palette.info.main,
		name: t("sessionStatus.validated"),
	},
	readyToPublish: {
		color: theme.palette.success.main,
		name: t("sessionStatus.readyToPublish"),
	},
});

export const getSessionStatusTag = (
	theme: Theme,
): {
	[key in SessionStatusType]: TagProps;
} => {
	const status = getSessionStatus(theme);
	return {
		draft: {
			backgroundColor: theme.palette.grey[300],
			borderColor: theme.palette.grey[300],
			fontColor: status.draft.color,
			children: <BulletText bulletColor={status.draft.color} text={status.draft.name} />,
		},
		validated: {
			backgroundColor: theme.palette.info.background,
			borderColor: theme.palette.info.background,
			fontColor: status.validated.color,
			children: <BulletText text={status.validated.name} />,
		},
		readyToPublish: {
			backgroundColor: theme.palette.success.background,
			borderColor: theme.palette.success.background,
			fontColor: status.readyToPublish.color,
			children: <BulletText text={status.readyToPublish.color} />,
		},
	};
};

export const getInvitationSpeakerStatusColor = (
	theme: Theme,
): {
	[key in InvitationStatusSpeaker]: string | undefined;
} => ({
	unsent: theme.palette.grey[700],
	declined: theme.palette.error.main,
	replaced: theme.palette.error.main,
	cancelled: theme.palette.neutral.main,
	waitingForAnswer: theme.palette.grey[500],
	accepted: theme.palette.success.main,
});
