import { type SpeakerRead, getPresentationSpeakers } from "@api";
import { API_VERSION_QUERY_PARAM, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const usePresentationSpeakers = (
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<SpeakerRead>> = {},
): UseQueryResult<Paginated<SpeakerRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.speakers, ...queryKeyPart, "Get", "List", clientId, eventId, queryStrings],
		queryFn: ({ signal }) =>
			getPresentationSpeakers({
				clientId,
				eventId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};
